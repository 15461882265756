import React, { useState } from "react"
import UnicornImg from "@assets/placeholder/icons/unicorn.svg"

const Complete = (props) => {
  return (
    <div className="container">
      <section className="section">
        <div className="container has-text-centered">
          <h2 className="title">アンケートのご協力ありがとうございました</h2>
          <p>お送りいただきましたアンケートを踏まえ、サービス向上に努めてまいります。</p>
        </div>
      </section>
                
      <section className="section is-paddingless">
        <div className="container">
          <div className="columns is-mobile is-centered">
            <img src="/images/ojigi.png" style={{maxHeight: 'calc(60vh - 4rem)'}} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Complete